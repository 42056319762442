var tangeloSlider = {}

tangeloSlider.slideIndex = 0

tangeloSlider.getSlides = function(selector) {
  return document.getElementsByClassName(selector)
}

tangeloSlider.getIndex = function() {
  return this.slideIndex
}

tangeloSlider.getNumberOfSlides = function() {
  return this.getSlides("slide-container").length - 1
}

tangeloSlider.setCurrentSlide = function() {
  var slides = this.getSlides("slide-container")
  for (var i = 0; i < slides.length; i++) {
    if (this.getIndex() === i) {
      slides[i].className = ("slide-container slide-container-active")
    } else {
      slides[i].className = ("slide-container slide-container-inactive")
    }
  }
}

tangeloSlider.nextSlide = function(currentSlide) {
  var slides = this.getNumberOfSlides()
  var index = this.getIndex()
  if (index < slides) {
    this.slideIndex++
  } else {
    this.slideIndex = 0
  }
  this.setCurrentSlide()
}

tangeloSlider.prevSlide = function(currentSlide) {
  var slides = this.getNumberOfSlides()
  var index = this.getIndex()
  if (index === 0) {
    this.slideIndex = slides
  } else {
    this.slideIndex--
  }
  this.setCurrentSlide()
}
