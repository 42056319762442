$(document).ready(function() {
  var tablecells = document.getElementsByTagName('td');
  var tablecellParagraphs = [];

  for (var i = 0; i < tablecells.length; i++) {
    if (tablecells[i].getElementsByTagName('p')[0] != undefined) {
      tablecellParagraphs.push(tablecells[i].getElementsByTagName('p'));
    }
  }

  for (var i = 0; i < tablecellParagraphs.length; i++) {
    // console.log(tablecellParagraphs[i][0])
	if(tablecellParagraphs[i][0]) {
		if(tablecellParagraphs[i][0].outerText.substring(0, 1) == '(') {
		  tablecellParagraphs[i][0].style.marginRight = '-4px';
		}
		if(tablecellParagraphs[i][0].outerText.substring(0, 9) == '(<strong>') {
		  tablecellParagraphs[i][0].style.marginRight = '-4px';
		}
	}
  }
});
