

// Creates an array of checkbox inputs.
function getCheckboxInputs() {
  var checkboxes = document.getElementsByTagName("input");
  var checkboxesMyReport = [];

  for (var i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].className == "my-report-checkbox") {
      checkboxesMyReport.push(checkboxes[i]);
    }
  }

  return checkboxesMyReport;
}

// Creates an array of ticked checkbox inputs.
function getTickedCheckboxInputs() {
  var checkboxInputs = getCheckboxInputs();
  var tickedCheckboxInputs = [];

  for (var i = 0; i < checkboxInputs.length; i++) {
    if (checkboxInputs[i].checked == true) {
      tickedCheckboxInputs.push(checkboxInputs[i]);
    }
  }

  return tickedCheckboxInputs;
}

// Gets the nodeids from the input box data attributes.
function getSectionIds() {
  var tickedCheckboxInputs = getTickedCheckboxInputs();
  var sectionIds = [];

  for (var i = 0; i < tickedCheckboxInputs.length; i++) {
    sectionIds.push(tickedCheckboxInputs[i].dataset.nodeinfo);
  }
  return sectionIds;
}

// Gets all the child inputs of a level 1 input.
function getChildren(nodeid) {
  checkboxInputs = getCheckboxInputs();
  var thisCheckbox;

  for (var i = 0; i < checkboxInputs.length; i++) {
    if (checkboxInputs[i].dataset.nodeinfo == nodeid) {
      thisCheckbox = checkboxInputs[i];
    }
  }

  var ulElement = thisCheckbox.parentNode.getElementsByTagName("ul");
  var liElements = ulElement[0].getElementsByTagName("li");
  var childInputs = [];

  for (var i = 0; i < liElements.length; i++) {
    childInputs.push(liElements[i].getElementsByTagName("input")[0]);
  }

  return childInputs;
}

// Gets the parent input of a level 1 input.
function getParent(nodeid) {
  checkboxInputs = getCheckboxInputs();
  var thisCheckbox;

  for (var i = 0; i < checkboxInputs.length; i++) {
    if (checkboxInputs[i].dataset.nodeinfo == nodeid) {
      thisCheckbox = checkboxInputs[i];
    }
  }

  var parentLi = thisCheckbox.parentNode.parentNode.parentNode;
  var parentInput = parentLi.getElementsByTagName("input")[0];

  return parentInput;
}

// Checks or unchecks the parent input depending on the 'checked' state of the children.
function tickOrUntickParent(nodeid) {
  var parent = getParent(nodeid);
  var siblingsAndSelf = getChildren(parent.dataset.nodeinfo);

  var tickParentCheckbox = true;
  for (var i = 0; i < siblingsAndSelf.length; i++) {
    if (siblingsAndSelf[i].checked == false) {
      tickParentCheckbox = false;
    }
  }

  parent.checked = tickParentCheckbox;
}

// Checks or unchecks the children inputs depending on the 'checked' state of the parent.
function tickOrUntickChildren(nodeid) {
  var children = getChildren(nodeid);
  var parent = getParent(children[0].dataset.nodeinfo);

  console.log(parent.checked, children);
  if (parent.checked == false) {
    for (var i = 0; i < children.length; i++) {
      children[i].checked = false;
    }
  } else {
    for (var i = 0; i < children.length; i++) {
      children[i].checked = true;
    }
  }
}

// Creates the url for pdf generation.
function generateURL(nodeid) {
  var sectionIds = getSectionIds();
  var url = '/pdfondemand/printpdf?docId=';

  url += document.getElementById("my-report-ul").dataset.documentid;

  if (sectionIds.length > 0) {
    url += "&nodes=" + sectionIds.join("-");
  }

  checkboxInputs = getCheckboxInputs();
  var thisCheckbox;

  for (var i = 0; i < checkboxInputs.length; i++) {
    if (checkboxInputs[i].dataset.nodeinfo == nodeid) {
      thisCheckbox = checkboxInputs[i];
    }
  }

  if (thisCheckbox.dataset.nodelevel == 1) {
    tickOrUntickChildren(nodeid);
  } else {
    tickOrUntickParent(nodeid);
  }

  //document.getElementById("btn-create-report").setAttribute("href", url);
  createCheckboxesCookie();
}

// Add section to cookie. Add to my report button in page options bar.
function addPageToReport() {
  var thisPageId = document.body.getAttribute("data-nodeinfo");
  var myReportData = splitCookie();

  var nodeAlreadyInReport = false;

  for (var i = 0; i < myReportData.length; i++) {
    if (myReportData[i] == thisPageId) {
      nodeAlreadyInReport = true;
    }
  }

  if (nodeAlreadyInReport == false) {
    myReportData.push(thisPageId);
  }

  myReportData = myReportData.join("&");

  var checkboxesCookie = "checkboxesCookie=" + myReportData + ";path=/;";
  document.cookie = checkboxesCookie;
}

// Creates the cookie to remember the user's selection between pages.
function createCheckboxesCookie() {
  var checkboxInputsArray = getSectionIds();
  var checkboxInputsString = checkboxInputsArray.join("&");

  var checkboxesCookie = "checkboxesCookie=" + checkboxInputsString + ";path=/;";
  document.cookie = checkboxesCookie;
}

// Retrieves the checkboxesCookie and sets the user's previous selection choice.
function getCheckboxesCookie() {
  var cookieData = splitCookie();
  console.log(cookieData);

  for (var indexCookie = 0; indexCookie < cookieData.length; indexCookie++) {
    var checkboxes = getCheckboxInputs();
    for (var indexCheckbox = 0; indexCheckbox < checkboxes.length; indexCheckbox ++) {
      if (cookieData[indexCookie] == checkboxes[indexCheckbox].dataset.nodeinfo) {
        checkboxes[indexCheckbox].checked = true;
      }
    }
  }
}

// Function to split up the cookie data into an array.
function splitCookie() {
  var cookie = document.cookie;
  var cookieArray = cookie.split(";");
  var cookieData = [];
  var regExp = /(\s)?checkboxesCookie=/gi;

  for (var i = 0; i < cookieArray.length; i++) {
    if (regExp.test(cookieArray[i])) {
      var cookieSplit = cookieArray[i].split("=");
      cookieData = cookieSplit[1].split("&");
    }
  }

  return cookieData;
}

// Shows or hides the children of the parent ul.
function toggleChildren(nodeid) {
  parentLiId = 'li-parent-' + nodeid;
  parentLi = document.getElementById(parentLiId);
  childrenUlId = 'ul-children-' + nodeid;
  childrenUl = document.getElementById(childrenUlId);

  if (childrenUl.style.display == 'none') {
    childrenUl.style.display = 'block';
    parentLi.style.listStyleImage = 'url(../../../xmlpages/resources/TXP/sbm_fr_2016/img/icon-arrow-down.png)';
  } else {
    childrenUl.style.display = 'none';
    parentLi.style.listStyleImage = 'url(../../../xmlpages/resources/TXP/sbm_fr_2016/img/icon-arrow.png)';
  }
}

// Get the cookie ond ocument load.
$(document).ready(function() {
  getCheckboxesCookie();
  
  $('#btn-create-report').on('click', function(e) {
   e.preventDefault();
 
   var cookieData = splitCookie();
	 
   if (cookieData.length > 0 && cookieData[0] !== "") {

     var url = '/pdfondemand/printpdf?docId=';
     url += document.getElementById("my-report-ul").dataset.documentid;
   
     url += "&nodes=" + cookieData.join("-");

     window.location.href = url;
   }
 });

});
